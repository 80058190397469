import React from 'react';
import { SvgIcon,Button, Box, Typography } from "@mui/material";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'core/contexts/localization';

import { useStyles } from './styles';
import { usePayment } from 'core/contexts/payment';
import logo from "assets/images/utriper-black-logo.png";

const PurchaseTemplate = ({ goBackUrl }) => {

    const { t: translate } = useTranslation();

    const classes = useStyles();

    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    // const email = params.get('email');

    const history = useHistory();

    const { getUserEmail } = usePayment();

    const renderBody = () => {
        try {
            const userEmail = getUserEmail();
            return (
                <React.Fragment>
            <Button
            variant='contained'
            className={classes.buttonStyle}
            onClick={() => history.replace(`/${goBackUrl}`)}
            >
                {translate("utils.backToSite")}
            </Button>
                    <Box>
                        <Box class="middle">
                            <Box class="inner" style={{ overflowWrap: "anywhere" }}>
                                <Box className={classes.container}
                                    sx={{ marginInline: "auto", borderColor: "#A6BA62", borderStyle: "solid", borderWidth: "0.5rem", paddingInline: "1rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}
                                >
                                    <Box style={{ width: "13rem", height: "4.5rem", marginInline: "auto", marginBottom: "1.5rem", marginTop: "1rem" }}>
                                        <img src={logo} style={{ width: "inherit", height: "inherit" }} />
                                    </Box>
                                    <Typography variant="h1" className={classes.text} style={{ marginBottom: "1rem" }}>
                                        <strong>
                                            {translate("templates.purchase.title")}
                                        </strong>
                                    </Typography>
                                    <SvgIcon style={{ height: "4rem", width: "4rem", color: "#A6BA62", display: "flex", marginInline: "auto", marginBottom: "1rem" }}>
                                        <CheckCircleOutlineRounded />
                                    </SvgIcon>
                                    <Box>
                                        <Typography variant="h1" className={classes.text} style={{ marginBottom: "3.5rem" }}>
                                            <strong>
                                                {`${translate("templates.purchase.beforeBodyEmail")} ${userEmail} ${translate("templates.purchase.afterBodyEmail")}`}
                                            </strong>
                                        </Typography>
                                    </Box>
                                    <Typography variant="h1" className={classes.text} style={{ marginBottom: "3.5rem" }}>
                                        <strong>
                                            {translate("templates.purchase.enjoy")}
                                        </strong>
                                    </Typography>
                                    <hr
                                        style={{
                                            border: "none",
                                            borderTop: "0.15rem dashed #000",
                                            color: "#fff",
                                            width: "50%",
                                            marginBottom: "3.5rem"
                                        }}
                                    />
                                    <Typography variant="h1" className={classes.text} style={{ marginBottom: "1rem" }}>
                                        <strong>
                                            {translate("templates.purchase.beforeEmail")}
                                        </strong>
                                    </Typography>
                                    <a href="mailto:hello@utriper.com" className={classes.text} style={{ marginBottom: "1rem" }}>
                                        <strong>
                                            hello@utriper.com
                                        </strong>
                                    </a>
                                    <Typography variant="h1" className={classes.text} style={{marginBottom: '1rem'}}>
                                        <strong>
                                            {translate("templates.purchase.afterEmail")}
                                        </strong>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </React.Fragment>
            );
        } catch (err) {
            return history.replace("/error");
        }
    }

    return (
        <React.Fragment>
            {renderBody()}
        </React.Fragment>
    );
}

export default PurchaseTemplate;