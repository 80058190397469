import React, { useState, useCallback } from 'react';
import OrderSummaryItem from './OrderSummaryItem';
import { Paper, Hidden, Box, Typography } from '@mui/material';
import OrderSummaryButtons from './OrderSummaryButtons';
import OrderSummaryPaymentText from './OrderSummaryPaymentText';
import { useTranslation } from 'core/contexts/localization';

const OrderSummary = (props) => {
  console.log(
    '[ORDER SUMMARY] EXPERIENCES ===',
    props.experiences,
    props.total,
  );

  const { t: translate } = useTranslation();

  const [checkoutExperiences, setCheckoutExperiences] = useState(
    props.experiences
  );
  const [totalPrice, setTotalPrice] = useState(props.total);
  const [currency, setCurrency] = useState(props.currency);

  const deleteExperience = (experience) => {
    console.log('PREV DEL ===', checkoutExperiences, '    ', experience);
    let index;
    checkoutExperiences.find((exp, idx) => {
      if (exp.key === experience.key) {
        index = idx;
        return true;
      }
    });
    const auxCheckoutExperiences = [...checkoutExperiences];
    auxCheckoutExperiences.splice(index, 1);
    console.log('index==', index, 'experiences', auxCheckoutExperiences);
    setCheckoutExperiences((prevExp) => {
      let newTotalPrice = auxCheckoutExperiences.reduce((sum, exp) => {
        return sum + Number.parseFloat(exp.price);
      }, 0);
      newTotalPrice = newTotalPrice.toFixed(2);
      setTotalPrice(newTotalPrice);
      return auxCheckoutExperiences;
    });
  };

  const onPaypalCreateOrder = useCallback(async () =>
    await props.onPaypalCreateOrder(checkoutExperiences),
    [props.onPaypalCreateOrder, checkoutExperiences]);


  const renderOrderSummaryItems = () => {
    return checkoutExperiences.map((experience) => {
      return (
        <OrderSummaryItem
          experience={experience}
          key={experience.key}
          deleteExperience={deleteExperience}
        />
      );
    });
  };

  const renderOrderSummaryPaper = (
    fixedHeight = true,
    overflowYAxis = 'auto'
  ) => {
    // de esta manera modularizo y para mobile no quiero un height especifico (ni overflow)
    const style = {
      overflowY: overflowYAxis,
      paddingTop: '1em',
      maxWidth: '100%',
      // boxShadow: "0em",
      overflowX: 'hidden',
      marginBottom: '0.5em',
      textAlign: '-webkit-center'
    };
    if (fixedHeight) {
      style.height = '23em';
    }

    return (
      <Paper elevation={0} style={style}>
        {renderOrderSummaryItems()}
      </Paper>
    );
  };

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <Typography variant='h3' style={{ marginBottom: '4vh', fontSize: '1.5rem', textAlign: 'center' }}>
        <strong>
          {translate('checkout.order_summary')}
        </strong>
      </Typography>
      <Hidden only="xs">{renderOrderSummaryPaper()}</Hidden>
      <Hidden smUp>{renderOrderSummaryPaper(false, 'hidden')}</Hidden>
      <OrderSummaryPaymentText
        currency={currency}
        total={totalPrice}
      // payNow={payNowPrice}
      />
      <OrderSummaryButtons
        onMercadoPagoCreateOrder={async () =>
          await props.onMercadoPagoCreateOrder(checkoutExperiences, totalPrice)
        }
        onCancel={props.onCancel}
        onPaypalCreateOrder={onPaypalCreateOrder}
        checkoutExperiences={checkoutExperiences}
        onBook={async () =>
          await props.onBook(checkoutExperiences, totalPrice)
        }
        totalPrice={totalPrice}
      />
    </Box>
  );
};

export default OrderSummary;
