import { useState, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';

import { useStyles } from './styles';

export const useLayout = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const drawerWidth = isMobile ? '100vw' : 240;
  const [open, setOpen] = useState(false);
  const classes = useStyles({ drawerWidth });

  const toggleMenu = useCallback(() => setOpen((open) => !open), []);

  return {
    classes,
    open,
    toggleMenu,
  };
};
