import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid2,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CheckIcon from '@mui/icons-material/Check';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { ModusChip } from 'components/ModusChip';


import { useTranslation } from 'core/contexts/localization';
import ExperienceModal from '../ExperienceModal';
import { useExperiences } from 'core/contexts/experience';
import { useMediaQuery } from '@mui/material';
import { formatMoney } from 'core/packages/format';

import {
  moods,
  getTranslatedName as getModusTranslatedName,
  isIdPresent as isModusIdPresent,
} from 'core/libs/import/Moods';

const ExperienceCard = (
  {
    calculateTotal,
    experienceName,
    experiencerName,
    experienceSubtitle,
    experienceDescription,
    experienceFromPrice,
    experienceDiscount,
    experienceOriginalPrice,
    experienceImage,
    experienceId,
    experienceDetailId,
    experienceLocation,
    experienceModuses,
    experienceEnvPolicyNumber,
    experienceEnvPolicyOther,
    experienceCancelPolicies,
    experienceIncludesPolicyNumber,
    experienceIncludesPolicyOther,
    experienceAnticipation,
    experiencePayNow,
    experienceContract,
    onBooked = () => {},
  },
  ...props
) => {
  const useStyles = makeStyles({
    root: {
      width: 360,
    },
  });
  const classes = useStyles();
  const history = useHistory();

  const { t: translate, i18n } = useTranslation();
  const i18nLang = i18n.language;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);

  // for debugging purposes only
  // console.log('props received on ExperienceCard: ', props);
  // console.log('image received on ExperienceCard: ', experienceImage);

  return (
    (<Card
                                                                                                                                                                key={experienceId}
                                                                                                                                                                width={360}
                                                                                                                                                                className={`p-3 experiences-grid__card ${classes.root}`}>
      <CardActionArea
        onClick={() => {
          if (!open) {
            setOpen(true);
          }
        }}>
        <div className="card-header overflow-hidden" style={{ height: 200 }}>
          <CardMedia
            className="relative"
            component="img"
            alt={experienceName}
            height="200"
            image={experienceImage}
            title={experienceName}
          />
          <div className="overlay absolute height-200" />
          <div
            style={{
              height: 'inherit',
              width: '100%',
              position: 'absolute',
              top: 0,
            }}>
            <Typography
              className="pl-3 my-a text-18 absolute text-white bold text-20 uppercase"
              style={{ bottom: 0 }}
              variant="body2"
              component="h5">
              {experienceName}
            </Typography>
          </div>
          <ExperienceModal
            open={open}
            closeCallback={() => setOpen(false)}
            id={experienceId}
            experiencerName={experiencerName}
            detailId={experienceDetailId}
            name={experienceName}
            subtitle={experienceSubtitle}
            description={experienceDescription}
            image={experienceImage}
            location={experienceLocation}
            lowerPrice={experienceFromPrice}
            moduses={experienceModuses}
            experienceEnvPolicyNumber={experienceEnvPolicyNumber}
            experienceEnvPolicyOther={experienceEnvPolicyOther}
            experienceCancelPolicies={experienceCancelPolicies}
            experienceIncludesPolicyNumber={experienceIncludesPolicyNumber}
            experienceIncludesPolicyOther={experienceIncludesPolicyOther}
            anticipation={experienceAnticipation}
            payNow={experiencePayNow}
          />
        </div>
        <CardContent style={isMobile ? {} : { minHeight: 160 }}>
          <div className="my-a pb-1 d-flex justify-content-between">
            <Typography
              className="mb-1"
              variant="body2"
              color="textSecondary"
              component="p">
              {/* {experienceDescription} */}
            </Typography>
            <Typography
              className="bg-green-300 price-badge bold"
              gutterBottom
              variant="h6"
              component="h6"
              hidden={experienceDiscount && experienceOriginalPrice}>
              {`${translate('experienceCard.fromPrice')} ${formatMoney(
                experienceFromPrice
              )} COP`}
            </Typography>
            <Typography
              className="bg-red-300 price-badge bold"
              gutterBottom
              variant="h6"
              component="h6"
              hidden={!experienceDiscount && !experienceOriginalPrice}>
              <del> 
              {`${translate('experienceCard.fromPrice')} ${formatMoney(
                experienceOriginalPrice
              )} COP`} 
              </del>
            </Typography>
            <Typography
              className="bg-green-300 price-badge bold"
              gutterBottom
              variant="h6"
              component="h6"
              hidden={!experienceDiscount && !experienceOriginalPrice}>
              {`${formatMoney(experienceFromPrice)} COP (${experienceDiscount ? experienceDiscount.toFixed(1).replace(/\.?0+$/, '') : '0'}% OFF)`}
            </Typography>
          </div>
          <Typography
            className="d-flex my-a pb-1"
            variant="body2"
            color="textSecondary"
            component="p">
            <LocationOnIcon />
            <p className="d-flex my-a pl-2">{`${experienceLocation}`}</p>
          </Typography>
          {/* <Typography
            className="d-flex my-a"
            variant="body2"
            color="textSecondary"
            component="p"
          >
            <CheckIcon />
            {<p className="d-flex my-a pl-2">
              {`Moduses: `}
            </p> }
            {
              experienceModuses.map(modus => {
                return (
                  <ModusChip
                    style={{
                      marginLeft: "0.75rem",
                    }}
                    label={modus}
                    color="primary"
                  />
                );
              })
            }
          </Typography> */}
          {experienceModuses.length > 0 &&
            experienceModuses.some((m) => isModusIdPresent(m, moods)) && (
              <Grid2 container>
                <Grid2 size={1}>
                  <CheckIcon />
                </Grid2>
                {/* <p className="d-flex my-a pl-2">
              {`Moduses: `}
            </p> */}
                <Grid2 size={1}>
                  {experienceModuses.map((modusId) => {
                    if (!isModusIdPresent(modusId, moods)) {
                      return null;
                    }

                    return (
                      <ModusChip
                        key={modusId}
                        style={{
                          marginRight: '0.75rem',
                          marginBottom: '0.2rem',
                        }}
                        label={getModusTranslatedName(modusId, i18nLang, moods)}
                        color="primary"
                      />
                    );
                  })}
                </Grid2>
              </Grid2>
            )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          onClick={() => {
            onBooked();
            history.push(`/booking/${experienceId}${experienceContract ? '/code/' + experienceContract : ''}`);
          }}
          className="w-100 triper-button bg-blue-300 bold">
          {/* <EventAvailableIcon /> */}
          <p className="my-a ml-2">{translate('experienceCard.book')}</p>
        </Button>
      </CardActions>
    </Card>)
  );
};

ExperienceCard.displayName = 'ExperienceCard';

export default ExperienceCard;
