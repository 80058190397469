import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Grid2, Fab, Box } from '@mui/material';
import { formatMoney } from 'core/packages/format';

const OrderSummaryItem = (props) => {
  const { name, currency, price, image, quantity } = props.experience;

  return (
    <Grid2 className="mb-5" container spacing={2} direction='column'>
      <Grid2
        size={{xs: 12, sm: 12, md: 12, lg: 4, xl: 4}}
        style={{
          width: '16em',
          height: '7em',
          alignSelf: 'center'
        }}>
        <Box
          sx={{
            borderRadius: '7%',
            width: '16em',
            height: '7em',
            backgroundImage: `url("${image}")`,
            backgroundSize: 'cover',
            backgroundPosition: "center"
          }}
        />
      </Grid2>
      <Grid2
        size={{xs: 12, sm: 12, md: 12, lg: 8, xl: 8}}
        container
        direction="column"
        style={{alignSelf: 'center'}}
        >
        <Grid2 container>
          <Grid2 size={12}>
            <h2>{name}</h2>
          </Grid2>
          {/* <Grid2 item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Fab
              size="small"
              onClick={() => props.deleteExperience(props.experience)}>
              <CloseIcon style={{ color: 'white' }} />
            </Fab>
          </Grid2> */}
        </Grid2>
        <Grid2 item>
          <h3>
            { price ?
              <strong>{currency} {formatMoney(price)} x {Number(quantity)}</strong>
            :
              <strong>FREE x {Number(quantity)}</strong>
            }
          </h3>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default OrderSummaryItem;
