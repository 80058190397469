import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    flexGrow: 1,
    // marginTop: 56,
    // marginLeft: 60,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    padding: theme.spacing(0),
    [theme.breakpoints.down('lg')]: {
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
    backgroundColor: 'white',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: (props) => props.drawerWidth,
  },
}));
