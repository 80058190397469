import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { isEmpty } from 'lodash';

import { useAuth } from 'core/contexts/auth';
import { useStyles } from './styles';

export const useAppBar = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const drawerWidth = isMobile ? '100vw' : 240;
  const classes = useStyles({ drawerWidth });
  const history = useHistory();

  // const { role, setRole, userInfo } = useAuth();


  // const showBecomeButton = isEmpty(userInfo?.experiencer);

  // const setUserRole = (selectedRole) => {
  //   setRole(selectedRole);
  //   history.push('/home');
  // };

  return {
    classes,
    // role,
    // setRole: setUserRole,
    // showBecomeButton,
    onBecomeClick: false,
  };
};
