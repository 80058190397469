import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { useHistory } from 'react-router-dom';

import ExperiencesData from './data';
import ExperienceModal from 'components/ExperienceModal';
import Card from 'components/ExperienceCard';


export default function ExperienceCard() {
  const useStyles = makeStyles({
    root: {
      maxWidth: 360,
    },
  });

  const classes = useStyles();
  const history = useHistory();
  console.log('data', ExperiencesData);
  return (
    <>
      {ExperiencesData.map((experience) => (
        <>
          <Card
            experienceImage={experience.image}
            experienceName={experience.name}
            experienceDescription={experience.description}
            experienceDuration={experience.duration}
            experienceAvailability={experience.availability}
            experiencePrice={experience.price}></Card>
        </>
      ))}
    </>
  );
}
