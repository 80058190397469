import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DialogCore = ({
  isOpen,
  onClose,
  title,
  content,
  actions
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions ? actions : (
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogCore;