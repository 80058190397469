import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

import { useTranslation } from 'core/contexts/localization';

const TextInput = ({
  meta: { touched, error },
  input: { ...inputProps },
  ignoreError = false,
  ...props
}) => {
  const { t: translate } = useTranslation();
  const [hasError, setHasError] = useState(props.initialError);
  const [value, setValue] = useState(undefined);

  useEffect(() => setHasError(props.initialError), [props.initialError]);

  return (<TextField
    {...inputProps}
    {...props}
    onChange={(event) => {
      setHasError((prevValue) => {
        const retValue = !props.setValidate(event.target.value);
        props.setFormError(retValue);
        return retValue;
      });
      if (props.onFieldChange) props.onFieldChange(event.target.value);
      if (props.onTextFieldChange) props.onTextFieldChange(event);
      setValue(event.target.value);
      inputProps.onChange(event);
    }}
    error={!!(touched && error && !ignoreError)}
    // helperText={touched && error && !ignoreError && translate(props.errorMessage)}
    helperText={
      (hasError || (!value && touched)) && !ignoreError &&
      translate(props.errorMessage(value))
    }
    fullWidth
    variant="outlined"
  />);
  };

TextInput.displayName = 'TextInput';

export default TextInput;
