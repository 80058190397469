import {
    Chip,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
} from '@mui/material';

export const ModusChip = (props) => {
    return (
        <Chip label={props.label} {...props} color={props.color} size="small"></Chip>
    );
};