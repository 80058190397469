import React from 'react';
import { DateTime } from 'luxon'
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import {
  formatDateToYYYYMMDD,
  TimeFormattedMinutesHours,
} from 'core/libs/core-ui/utils/dateTimeFormatted';
import palette from 'core/libs/core-ui/theme/colors-palette';
import { useTranslation } from 'core/contexts/localization';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

const useStyles = makeStyles((theme) => ({
  buttonCheckout: {
    backgroundColor: palette.utriper.darkgreen,
    color: '#fff',
    '&:hover': {
      backgroundColor: palette.utriper.green,
    },
  },
}));
const ExperienceDetails = ({
  info,
  dateSlot,
  quantity,
  experience,
  ...props
}) => {
  const optionsTime = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    // timeZone: 'UTC',
  }
  const { time, price, currency, priceTotal } = info || {};
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const handleCheckout = () => {
    history.push({
      pathname: '/checkout',
      state: {
        id: info.id,
        experienceDetailId: experience.expDetailId,
        groupSize: quantity,
        price: priceTotal,
      },
    });
  };

  return (
    <Box>
      <CardContent>
        <Typography variant="h5" component="h2">
          {experience.name} Details
        </Typography>
        <List>
          <ListItem>
            <EventIcon />
            <ListItemText
              primary={`Date: ${DateTime.fromISO(info.startUTC).setZone(info.timezone).toFormat('EEE dd MMMMM yyyy')}`}
              secondary=''
            />
          </ListItem>
          <ListItem>
            <PeopleIcon />
            <ListItemText
              primary={`Start: ${DateTime.fromISO(info.startUTC).setZone(info.timezone).toFormat('HH:mm')}`}
              secondary=''
            />
          </ListItem>
          <ListItem>
            <AttachMoneyIcon />
            <ListItemText
              primary={`Total: ${ priceTotal ? `${currency} ${MoneyFormattedUtils(priceTotal)}` : 'FREE'}`}
              secondary={``}
            />
          </ListItem>
        </List>
        <Divider />
        <Button
          className={classes.buttonCheckout}
          variant="contained"
          fullWidth
          onClick={handleCheckout}>
          {translate('utils.goToCheckout')}
        </Button>
      </CardContent>
    </Box>
  );
};

export default ExperienceDetails;
