import React from "react";
import { Grid2 } from "@mui/material";
import DestinationCard from "./components/DestinationCard";

import destinations from "./data";

const DestinationsList = ({
    className
}) => {
    return (
        <Grid2 container spacing={3} className={className}>
            {destinations.map((destination) => (
                <Grid2 key={destination.id} size={{xs: 12, sm: 12, md: 4, lg: 4, xl: 4}}>
                    <DestinationCard
                        name={destination.name}
                        cardText={destination.cardText}
                        lat={destination.lat}
                        lng={destination.lng}
                        imageUrl={destination.imageUrl}
                        url={destination.url}
                    />
                </Grid2>
            ))}
        </Grid2>
    );
};

export default DestinationsList;
