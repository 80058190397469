import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-final-form';
import { Grid2, Typography, Button, ButtonGroup, Hidden, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'core/contexts/localization';
import { nationalityList } from 'core/constants';
import OrderSummary from './OrderSummary';
import TextField from './TextField';
import AutocompleteField from './AutocompleteField';
// import DateField from './DateField';
import { Field } from 'react-final-form';
import PhoneNumber from './PhoneNumber';
import { CheckoutService } from 'core/api/services/checkout';
import { usePayment } from 'core/contexts/payment';
import { useExperiences } from 'core/contexts/experience';
import { useHistory, useLocation } from 'react-router-dom';
import Checkbox from 'components/Checkbox';
import TermsAndConditions from 'components/TermsAndConditions';
import { useHostel } from 'core/contexts/hostel';

const useStyles = makeStyles({
    textFeild: {
        marginBottom: '1rem',
    },
    phoneField: {
        width: '100%',
    },
    countryField: {
        width: '100%',
        color: 'red',
    },
});

const Checkout = (props) => {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const history = useHistory();
    const { code } = useHostel();

    const location = useLocation();

    const experienceDetailId = location.state.experienceDetailId;
    const groupSize = location.state.groupSize;

    const {
        mercadoPago,
        submitted,
        userData,
        setUserData,
        formErrors,
        setFormErrors,
        agreeWithTermsAndConditions,
        setAgreeWithTermsAndConditions,
        resetForm,
        paymentType,
        setPaymentType,
        triperFormErrors,
        setTriperFormErrors,
    } = usePayment();

    const { bookedExperiences, hasBookedExperiences, timeOfArrival } =
        useExperiences();
    console.log('bookedExperiences', bookedExperiences);

    // useEffect(() => {
    //   console.log("hereeee ", hasBookedExperiences)
    //   if(!hasBookedExperiences) {
    //     history.replace("/");
    //   }
    // }, [bookedExperiences]);

    useEffect(() => {
        resetForm();
        if (bookedExperiences[0] === undefined) {
            history.replace('/');
        }
        userData.tripers = [];
        let auxTriperFormErrors = [];
        for (let i = 0; i < groupSize; i++) {
            const triper = {
                firstName: '',
                lastName: '',
                email: '',
                dob: '',
                personalIdType: 0,
                personalId: '',
            };
            const triperError = {
                firstName: i !== 0,
                lastName: i !== 0,
                email: i !== 0,
                country: i !== 0,
                dob: bookedExperiences[0].requireId,
                personalId: bookedExperiences[0].requireId,
            };
            if (i === 0) {
                triper.firstName = userData.firstName;
                triper.lastName = userData.lastName;
                triper.email = userData.email;
                triper.country = userData.nationality;
            }
            userData.tripers.push(triper);
            auxTriperFormErrors.push(triperError);
        }
        setUserData(userData);
        if(bookedExperiences[0].currency && bookedExperiences[0].currency.toLowerCase() !== 'cop') {
            let newPaymentType = paymentType; 
            newPaymentType = 2; // Add paypal TODO: We should manage this with types...
            setPaymentType(newPaymentType);
        }
        
        // setTriperFormErrors(auxTriperFormErrors)
    }, []);

    const [openModal, setOpenModal] = useState(false);
    const [colorNational, setColorNational] = useState('default');
    const [colorInternational, setColorInternational] = useState('default');
    const [sameTriper, setSameTriper] = useState(true);

    // initially assume there are errors, but the form, of course, is not submitted (avoiding to set required when
    // user not even clicked the submit button)

    const validate = (value) => {
        return value;
    };

    const validateEmail = (email) => {
        if (!email) return false;
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    // const canSubmit = useCallback((checkoutExperiences) => {
    //   console.log(checkoutExperiences);
    //   console.log(formErrors, ' +++ ', submitted);
    //   const formHasErrors = Object.values(formErrors).some(
    //     (errorValue) => errorValue
    //   );
    //   setSubmitted(true);
    //   if (formHasErrors || !agreeWithTermsAndConditions) {
    //     console.log('FORM HAS ERRORS', formHasErrors, agreeWithTermsAndConditions);
    //     return false;
    //   }
    //   console.log('SUBMIT===', userData);
    //   setCheckoutExperiences(checkoutExperiences);
    //   // props.onNextStep();
    //   return true;
    // }, [formErrors, agreeWithTermsAndConditions]);

    const onBookCreateOrder = async (checkoutExperiences) => {
        const res = await CheckoutService.createBookOrder(
            checkoutExperiences,
            userData,
            timeOfArrival,
            experienceDetailId,
            groupSize,
            code
        );
        if (res) {
            history.replace('/purchase');
        } else {
            history.replace('/error');
        }
    };

    const onMercadoPagoCreateOrder = async (checkoutExperiences) => {
        const id = await CheckoutService.createPreference(
            checkoutExperiences,
            userData,
            timeOfArrival,
            experienceDetailId,
            groupSize,
            code
        );
        window.location.href = `https://www.mercadopago.com.co/checkout/v1/redirect?pref_id=${id}`;
        // mercadoPago.checkout({
        //   autoOpen: true,
        //   preference: { id },
        // });
        // await props.onMercadoPagoCreateOrder(checkoutExperiences, userData);
    };

    const onPaypalCreateOrder = async (checkoutExperiences) => {
        console.log('inside paypal create order user data is ==== ', userData);
        return await CheckoutService.createPaypalOrder(
            checkoutExperiences,
            userData,
            timeOfArrival,
            experienceDetailId,
            groupSize,
            code
        );
    };

    const renderTermsAndConditions = () => {
        return (
            <>
                {translate('termsAndConditions.accept')}
                <a
                    onClick={(e) => {
                        console.log('CLICKED TERM AND CONDITIONS', e);
                        setOpenModal(true);
                        e.preventDefault();
                    }}>
                    {translate('termsAndConditions.link')}
                </a>
            </>
        );
    };

    const hideField = (index) => {
        if (sameTriper && index === 0) {
            return true;
        }
        return false;
    };

    return (
        (<React.Fragment>
            {/* <style>
        {
          `.MuiInputBase-root {
              font-size: 1rem
          }`
        }
      </style> */}
            <Form
                onSubmit={() => {}}
                render={({ handleSubmit, form }) => (
                    <form>
                        <Grid2 container style={{ marginTop: '7vh' }}>
                            <Grid2
                                size={{xs: 12, sm: 6, md: 6, lg: 5, xl: 5}}
                                style={{ paddingLeft: '5vw', display: 'flex', justifyContent: 'center' }}>
                                <Box
                                    sx={{
                                        maxWidth: '75%',
                                        marginBottom: '5vh',
                                    }}>
                                    <Box sx={{ marginBottom: '5vh', fontSize: '2rem' }}>
                                        <strong>
                                            {bookedExperiences[0]?.priceTotal ==
                                            0
                                                ? translate(
                                                      'checkout.order_details'
                                                  )
                                                : translate(
                                                      'checkout.billing_details'
                                                  )}
                                        </strong>
                                    </Box>
                                    <TextField
                                        className={classes.textFeild}
                                        name="firstname"
                                        onTextFieldChange={(event) => {
                                            const auxUserData = {
                                                ...userData,
                                                firstName: event.target.value,
                                            };
                                            // Set first triper as well
                                            if (sameTriper) {
                                                const auxtriperData =
                                                    userData.tripers[0];
                                                auxtriperData.firstName =
                                                    event.target.value;
                                                auxUserData.tripers[0] =
                                                    auxtriperData;
                                            }
                                            setUserData(auxUserData);
                                        }}
                                        required
                                        validate={validate}
                                        label={translate('checkout.first_name')}
                                        errorMessage={(value) =>
                                            translate('form.required')
                                        }
                                        setFormError={(value) =>
                                            setFormErrors({
                                                ...formErrors,
                                                firstName: value,
                                            })
                                        }
                                        initialError={
                                            formErrors.firstName && submitted
                                        }
                                    />
                                    <TextField
                                        className={classes.textFeild}
                                        name="lastname"
                                        onTextFieldChange={(event) => {
                                            const auxUserData = {
                                                ...userData,
                                                lastName: event.target.value,
                                            };
                                            // Set first triper as well
                                            if (sameTriper) {
                                                const auxtriperData =
                                                    userData.tripers[0];
                                                auxtriperData.lastName =
                                                    event.target.value;
                                                auxUserData.tripers[0] =
                                                    auxtriperData;
                                            }
                                            setUserData(auxUserData);
                                        }}
                                        required
                                        validate={validate}
                                        errorMessage={(value) =>
                                            translate('form.required')
                                        }
                                        label={translate('checkout.last_name')}
                                        setFormError={(value) =>
                                            setFormErrors({
                                                ...formErrors,
                                                lastName: value,
                                            })
                                        }
                                        initialError={
                                            formErrors.lastName && submitted
                                        }
                                    />
                                    <TextField
                                        className={classes.textFeild}
                                        name="email"
                                        onTextFieldChange={(event) => {
                                            const auxUserData = {
                                                ...userData,
                                                email: event.target.value,
                                            };
                                            // Set first triper as well
                                            if (sameTriper) {
                                                const auxtriperData =
                                                    userData.tripers[0];
                                                auxtriperData.email =
                                                    event.target.value;
                                                auxUserData.tripers[0] =
                                                    auxtriperData;
                                            }
                                            setUserData(auxUserData);
                                        }}
                                        required
                                        validate={validateEmail}
                                        errorMessage={(value) => {
                                            if (!value)
                                                return translate(
                                                    'form.required'
                                                );
                                            if (value.length > 0)
                                                return translate(
                                                    'form.invalid.email'
                                                );
                                        }}
                                        label="Email"
                                        setFormError={(value) =>
                                            setFormErrors({
                                                ...formErrors,
                                                email: value,
                                            })
                                        }
                                        initialError={
                                            formErrors.email && submitted
                                        }
                                    />
                                    <Field
                                        id="phone"
                                        onFieldChange={(value, country) => {
                                            const auxUserData = {
                                                ...userData,
                                                phoneNumber: value,
                                                phoneNumberCountryCode: country,
                                            };
                                            setUserData(auxUserData);
                                        }}
                                        className={`${classes.textFeild} ${classes.phoneField}`}
                                        name="mobilePhone"
                                        component={PhoneNumber}
                                        label={
                                            translate('checkout.phone_number') +
                                            ' *'
                                        }
                                        initialCountry={'us'}
                                        validateField={(value, countryCode) =>
                                            value.length > 0 &&
                                            value.startsWith(countryCode) &&
                                            value.length > countryCode.length
                                        }
                                        setFormError={(value) =>
                                            setFormErrors({
                                                ...formErrors,
                                                phoneNumber: value,
                                                phoneNumberCountryCode: value,
                                            })
                                        }
                                        errorMessage={translate(
                                            'form.required'
                                        )}
                                        initialError={
                                            formErrors.phoneNumber &&
                                            formErrors.phoneNumberCountryCode &&
                                            submitted
                                        }
                                    />

                                    <AutocompleteField
                                        className={classes.countryField}
                                        name="nationality"
                                        label={
                                            translate('checkout.country') + ' *'
                                        }
                                        options={nationalityList}
                                        getOptionLabels={(option) =>
                                            option.en_short_name
                                        }
                                        onFieldChange={(event) => {
                                            const auxUserData = {
                                                ...userData,
                                                country:
                                                    event.target.textContent,
                                            };
                                            // Set first triper as well
                                            if (sameTriper) {
                                                const auxtriperData =
                                                    userData.tripers[0];
                                                auxtriperData.country =
                                                    event.target.textContent;
                                                auxUserData.tripers[0] =
                                                    auxtriperData;
                                            }
                                            setUserData(auxUserData);
                                        }}
                                        required
                                        validate={validate}
                                        errorMessage={translate(
                                            'form.required'
                                        )}
                                        setFormError={(value) =>
                                            setFormErrors({
                                                ...formErrors,
                                                country: value,
                                            })
                                        }
                                        initialError={
                                            formErrors.country && submitted
                                        }
                                    />
                                    <Hidden lgDown>
                                        <h2>
                                            <strong>
                                                {/* {translate('checkout.order_details')} */}
                                            </strong>
                                        </h2>
                                    </Hidden>
                                    { bookedExperiences[0].currency && bookedExperiences[0].currency.toLowerCase() !== 'cop' ? '' : 
                                    <ButtonGroup
                                        hidden={
                                            bookedExperiences[0]?.priceTotal ==
                                            0
                                        }
                                        style={{
                                            minWidth: '150px',
                                            height: '70px',
                                            marginBottom: '5vh',
                                            marginTop: '0.4rem'
                                        }}
                                        disableElevation
                                        variant="contained"
                                        color="default">
                                        <Button
                                            style={{ width: '50%' }}
                                            color={colorNational}
                                            onClick={() => {
                                                setColorNational('primary');
                                                setColorInternational(
                                                    'default'
                                                );
                                                let newPaymentType =
                                                    paymentType;
                                                newPaymentType = 1;
                                                setPaymentType(newPaymentType);
                                            }}>
                                            {translate(
                                                'checkout.payment_national'
                                            )}
                                        </Button>
                                        <Button
                                            style={{ width: '50%' }}
                                            color={colorInternational}
                                            onClick={() => {
                                                setColorNational('default');
                                                setColorInternational(
                                                    'primary'
                                                );
                                                let newPaymentType =
                                                    paymentType;
                                                newPaymentType = 2;
                                                setPaymentType(newPaymentType);
                                            }}>
                                            {translate(
                                                'checkout.payment_international'
                                            )}
                                        </Button>
                                    </ButtonGroup>
                                    }
                                    <Field
                                        style={{
                                            paddingTop: '2em',
                                        }}
                                        hasError={
                                            submitted &&
                                            !agreeWithTermsAndConditions
                                        }
                                        errorMessage={translate(
                                            'form.required'
                                        )}
                                        checked={agreeWithTermsAndConditions}
                                        onChange={(value) => {
                                            console.log('hereee ==== ', value);
                                            setAgreeWithTermsAndConditions(
                                                value
                                            );
                                        }}
                                        name="termsAndConditions"
                                        component={Checkbox}
                                        labelComponent={renderTermsAndConditions()}
                                    />
                                </Box>
                                {/* {!bookedExperiences[0].requireId ? "" : (
                  <Grid2 container style={{ marginTop: "7vh" }}>
                    <Grid2 item xs={12} sm={12} md={12} lg={12} xl={12}>

                      <h2 style={{ marginBottom: '5vh' }}>
                        <strong>
                          {translate('checkout.triper_details')}
                        </strong>
                      </h2>

                    </Grid2>

                    <Grid2 container item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {
                        userData.tripers.map((triper, index) => {
                          return (
                            <>

                              <Typography>
                                {`Triper ${index + 1}`} {index !== 0 ? "" : (
                                  <Field
                                    style={{
                                      paddingTop: '2em',
                                    }}
                                    checked={sameTriper}
                                    onChange={(value) => {
                                      console.log("hereee ==== ", value)
                                      setSameTriper(value);
                                      if (value) { // We copy billing details to Triper 1 and set error to false

                                        const auxUserData = {
                                          ...userData,
                                        };
                                        const auxtriperData = userData.tripers[0];
                                        auxtriperData.firstName = userData.firstName;
                                        auxtriperData.lastName = userData.lastName;
                                        auxtriperData.email = userData.email;
                                        auxtriperData.country = userData.nationality;
                                        auxUserData.tripers[0] = auxtriperData;

                                        const auxTriperFormErrors = triperFormErrors;
                                        auxTriperFormErrors[0].firstName = false;
                                        auxTriperFormErrors[0].lastName = false;
                                        auxTriperFormErrors[0].email = false;
                                        auxTriperFormErrors[0].country = false;

                                        setUserData(auxUserData);
                                        setTriperFormErrors(auxTriperFormErrors)

                                      } else { // We clean triper 1 and set errors to true
                                        const auxUserData = {
                                          ...userData,
                                        };
                                        const auxtriperData = userData.tripers[0];
                                        auxtriperData.firstName = "";
                                        auxtriperData.lastName = "";
                                        auxtriperData.email = "";
                                        auxtriperData.country = "";
                                        auxUserData.tripers[0] = auxtriperData;

                                        const auxTriperFormErrors = triperFormErrors;
                                        auxTriperFormErrors[0].firstName = true;
                                        auxTriperFormErrors[0].lastName = true;
                                        auxTriperFormErrors[0].email = true;
                                        auxTriperFormErrors[0].country = true;

                                        setUserData(auxUserData);
                                        setTriperFormErrors(auxTriperFormErrors)
                                      }
                                    }}
                                    name="sameTriper"
                                    component={Checkbox}
                                    labelComponent={translate('checkout.same_person')}
                                  />
                                )}
                              </Typography>

                              <Grid2 hidden={hideField(index)} container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ paddingLeft: "5vw", marginTop: '5px' }}>
                                <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6} >
                                  <TextField
                                    name={`tripers[${index}].firstname`}
                                    onTextFieldChange={(event) => {
                                      const auxUserData = {
                                        ...userData,
                                      };
                                      const auxtriperData = userData.tripers[index];
                                      auxtriperData.firstName = event.target.value;
                                      auxUserData.tripers[index] = auxtriperData;
                                      setUserData(auxUserData);
                                    }}
                                    required
                                    validate={validate}
                                    errorMessage={(value) => translate('form.required')}
                                    label={translate('checkout.first_name')}
                                    setFormError={(value) => {
                                      const auxTriperFormErrors = triperFormErrors;
                                      auxTriperFormErrors[index].firstName = value;
                                      setTriperFormErrors(auxTriperFormErrors)
                                    }}
                                  />
                                </Grid2>
                                <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <TextField
                                    name={`tripers[${index}].lastname`}
                                    onTextFieldChange={(event) => {
                                      const auxUserData = {
                                        ...userData,
                                      };
                                      const auxtriperData = userData.tripers[index];
                                      auxtriperData.lastName = event.target.value;
                                      auxUserData.tripers[index] = auxtriperData;
                                      setUserData(auxUserData);
                                    }}
                                    required
                                    validate={validate}
                                    errorMessage={(value) => translate('form.required')}
                                    label={translate('checkout.last_name')}
                                    setFormError={(value) => {
                                      const auxTriperFormErrors = triperFormErrors;
                                      auxTriperFormErrors[index].lastName = value;
                                      setTriperFormErrors(auxTriperFormErrors)
                                    }}
                                  />
                                </Grid2>
                              </Grid2>
                              <Grid2 hidden={hideField(index)} container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ paddingLeft: "5vw" }}>
                                <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6} >
                                  <TextField
                                    className="mt-5"
                                    name={`tripers[${index}].email`}
                                    onTextFieldChange={(event) => {
                                      const auxUserData = {
                                        ...userData,
                                      };
                                      const auxtriperData = userData.tripers[index];
                                      auxtriperData.email = event.target.value;
                                      auxUserData.tripers[index] = auxtriperData;
                                      setUserData(auxUserData);
                                    }}
                                    required
                                    validate={validateEmail}
                                    errorMessage={(value) => {
                                      if (!value) return translate('form.required');
                                      if (value.length > 0)
                                        return translate('form.invalid.email');
                                    }}
                                    label="Email"
                                    setFormError={(value) => {
                                      const auxTriperFormErrors = triperFormErrors;
                                      auxTriperFormErrors[index].email = value;
                                      setTriperFormErrors(auxTriperFormErrors)
                                    }}
                                  />
                                </Grid2>
                                <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6} >
                                  <AutocompleteField
                                    className="mt-5 mb-2"
                                    name={`tripers[${index}].nationality`}
                                    label={
                                      translate('checkout.country') + ' *'
                                    }
                                    options={nationalityList}
                                    getOptionLabels={(option) => option.en_short_name}
                                    onFieldChange={(event) => {
                                      const auxUserData = {
                                        ...userData,
                                      };
                                      const auxtriperData = userData.tripers[index];
                                      auxtriperData.country = event.target.textContent;
                                      auxUserData.tripers[index] = auxtriperData;
                                      setUserData(auxUserData);
                                    }}
                                    required
                                    validate={validate}
                                    errorMessage={translate('form.required')}
                                    setFormError={(value) => {
                                      const auxTriperFormErrors = triperFormErrors;
                                      auxTriperFormErrors[index].country = value;
                                      setTriperFormErrors(auxTriperFormErrors)
                                    }}
                                  />
                                </Grid2>
                              </Grid2>
                              <Grid2 container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ paddingLeft: "5vw", marginBottom: '16px' }}>
                                <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6} >
                                  <DateField
                                    className="mt-5 mb-2"
                                    name={`tripers[${index}].dob`}
                                    label={translate('checkout.dob')}
                                    onChange={(event) => {
                                      const auxUserData = {
                                        ...userData,
                                      };
                                      const auxtriperData = userData.tripers[index];
                                      const auxTriperFormErrors = triperFormErrors;
                                      auxTriperFormErrors[index].dob = true;
                                      if (Date.parse(event)) {
                                        auxtriperData.dob = event.toISOString().substr(0, 10);
                                        auxUserData.tripers[index] = auxtriperData;
                                        setUserData(auxUserData);
                                        auxTriperFormErrors[index].dob = false;
                                      }
                                      setTriperFormErrors(auxTriperFormErrors)
                                    }}
                                    required
                                    validate={validate}
                                    errorMessage={translate('form.required')}
                                  />
                                </Grid2>
                                <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6} >
                                  <TextField
                                    className="mt-5"
                                    name={`tripers[${index}].personalid`}
                                    onTextFieldChange={(event) => {
                                      const auxUserData = {
                                        ...userData,
                                      };
                                      const auxtriperData = userData.tripers[index];
                                      auxtriperData.personalId = event.target.value;
                                      auxUserData.tripers[index] = auxtriperData;
                                      setUserData(auxUserData);
                                    }}
                                    required
                                    validate={validate}
                                    errorMessage={(value) => {
                                      if (!value) return translate('form.required');
                                      if (value.length > 0)
                                        return translate('form.invalid.personalId');
                                    }}
                                    label={translate('checkout.passport')}
                                    setFormError={(value) => {
                                      const auxTriperFormErrors = triperFormErrors;
                                      auxTriperFormErrors[index].personalId = value;
                                      setTriperFormErrors(auxTriperFormErrors)
                                    }}
                                  />
                                </Grid2>
                              </Grid2>
                            </>
                          );
                        })
                      }
                    </Grid2>
                  </Grid2>
                )} */}
                            </Grid2>
                            <Grid2
                                size={{xs: 12, sm: 6, md: 6, lg: 7, xl: 7}}
                                xs={12}
                                sm={6}
                                md={6}
                                lg={7}
                                xl={7}
                                style={{ paddingLeft: '5vw' }}>
                                <OrderSummary
                                    experiences={bookedExperiences}
                                    total={bookedExperiences[0]?.priceTotal}
                                    currency={bookedExperiences[0]?.currency}
                                    // quiero manejar las experiencias de otra forma, no me sirve handleSubmit
                                    onMercadoPagoCreateOrder={
                                        onMercadoPagoCreateOrder
                                    }
                                    onCancel={() => history.goBack()}
                                    onPaypalCreateOrder={onPaypalCreateOrder}
                                    onBook={onBookCreateOrder}
                                />
                            </Grid2>
                        </Grid2>
                    </form>
                )}
            />
            <TermsAndConditions
                open={openModal}
                onClose={() => setOpenModal(false)}
                onClick={() => {
                    setAgreeWithTermsAndConditions(
                        !agreeWithTermsAndConditions
                    );
                    setOpenModal(false);
                }}
            />
        </React.Fragment>)
    );
};

export default Checkout;
