import { IconButton } from "@mui/material";
import { Facebook, LinkedIn, Twitter, YouTube, Instagram } from "@mui/icons-material";

export const Icons = () => {
    return (
        (<div>
            <IconButton
                style={{ color: "black" }}
                onClick={() => window.open("https://www.facebook.com/people/UTriper/100080151082050/?mibextid=ZbWKwL", "blank")}
                size="large">
                <Facebook />
            </IconButton>
            <IconButton
                style={{ color: "black" }}
                onClick={() => window.open("https://twitter.com/utriperok", "blank")}
                size="large">
                <Twitter />
            </IconButton>
            <IconButton
                style={{ color: "black" }}
                onClick={() => window.open("https://instagram.com/utriperok", "blank")}
                size="large">
                <Instagram />
            </IconButton>
            <IconButton
                style={{ color: "black" }}
                onClick={() => window.open("https://www.linkedin.com/company/utriper/", "blank")}
                size="large">
                <LinkedIn />
            </IconButton>
            <IconButton
                style={{ color: "black" }}
                onClick={() => window.open("https://www.youtube.com/@uTriper/videos", "blank")}
                size="large">
                <YouTube />
            </IconButton>
        </div>)
    );
}