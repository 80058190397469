import React, { useState, useEffect } from 'react';
import { FormControl, GlobalStyles } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginBottom: theme.spacing(1),
    },
}));

export const DateInput = ({
    addToDate,
    values_,
    input,
    disabled,
    disableFuture,
    disablePast,
    meta,
    label,
    name,
    onChange,
    ...props
}) => {
    const [hasError, setHasError] = useState(props.initialError);
    const [selectedDate, handleDateChange] = useState(null);
    const classes = useStyles();

    return (
        <FormControl fullWidth sx={{mt: '1rem'}}>
                <GlobalStyles
                    styles={{
                        '.Mui-disabled': {
                            opacity: '0.5 !important',
                        },
                        width: '100%',
                        marginTop: '1rem'
                    }}
                />
                <DatePicker
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    openTo="year"
                    views={['year', 'month', 'day']}
                    inputVariant="outlined"
                    id={name}
                    label={label}
                    disabled={disabled || false}
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={(value) => {
                        setHasError((prevValue) => {
                            const retValue = !props.setValidate(value);
                            props.setFormError(retValue);
                            return retValue;
                        });
                        handleDateChange(value);
                        if(onChange) {onChange(value);}
                        input.onChange(value);
                    }}
                    error={meta.touched ? !!meta.error : false}
                    helperText={meta.touched ? meta.error : ''}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
        </FormControl>
    );
};

DateInput.displayName = 'DateInput';
