import { useEffect } from 'react';
import { CheckoutService } from 'core/api/services/checkout';
import { usePayment } from 'core/contexts/payment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'core/contexts/localization';

import { LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';

const Validate = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const paymentPlatform = params.get('payment-platform');
  const email = params.get('email');

  const history = useHistory();
  const { t: translate } = useTranslation();

  const { setUserEmail } = usePayment();

  useEffect(() => {
    try {
      if (paymentPlatform === 'MercadoPago') {
        // console.log('--------------------------------')
        // console.log('preference_id: ', params.get('preference_id'))
        // console.log('payment_id: ', params.get('payment_id'))
        // console.log('--------------------------------')
        CheckoutService.onValidationMercadoPago(
          params.get('preference_id'),
          params.get('payment_id'),
          params.get('email'),
          params.get('code'),
          params.get('payment_type'),
          params.get('external_reference')
        ).then(({ data, errors }) => {
          // console.log('data from res: ', data);
          // console.log('errors from res: ', errors);
          // console.log('errors length: ', errors.length);
          // if !errors & approved payment
          setUserEmail(email);
          if (errors.length === 0 && data[0].status === 'approved') {
            history.replace('/purchase');
          }
          // else if errors found
          else if (errors.length > 0) {
            history.replace('/error');
            throw new Error(errors);
          }
          // else pending -> !errors & approved payment
          else {
            history.replace('/pending');
          }
        });
      } else {
        toast.error('Payment platform not found', {
          position: 'top-right',
          theme: 'colored',
        });
        throw new Error('Payment platform not found');
      }
    } catch (err) {
      toast.error(translate('templates.error.generic'), {
        position: 'top-right',
        theme: 'colored',
      });
      history.replace('/error');
    }
  }, []);

  return (
    <div
      style={{
        width: '15rem',
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
      }}>
      <h1 style={{ textAlign: 'center' }}>
        {translate('checkout.validating')}
      </h1>
      <LinearProgress color="primary" />
    </div>
  );
};

export default Validate;
