import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import logo from "assets/images/utriper-black-logo.png";
import { useStyles } from './styles';

// function HideOnScroll(props) {
//     const { children, window } = props;
//     // Note that you normally won't need to set the window ref as useScrollTrigger
//     // will default to window.
//     // This is only being set here because the demo is in an iframe.
//     const trigger = useScrollTrigger({ target: window ? window() : undefined });

//     return (
//         <Slide appear={false} direction="down" in={!trigger}>
//             {children}
//         </Slide>
//     );
// }

// HideOnScroll.propTypes = {
//     children: PropTypes.element.isRequired,
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     window: PropTypes.func,
// };

export default function NavBar(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />
            {/* <HideOnScroll {...props}> */}
            <Slide appear={false} direction="down" in={true}>
                <AppBar>
                    <Toolbar className={classes.toolbarStyle} >
                        <IconButton href='/' size="large">
                            <img
                                alt="global-triper-logo"
                                className={classes.logo}
                                src={logo}
                            />
                        </IconButton>
                            {/* <Typography variant="h6">Scroll to Hide App Bar</Typography> */}
                        {props.inBarComponent}
                    </Toolbar>
                </AppBar>
            </Slide>
            {/* </HideOnScroll> */}
            <Toolbar />
            <Container>
                <Box sx={{my: 2}}>
                    {props.outBarComponent}
                </Box>
            </Container>
        </React.Fragment>
    );
}