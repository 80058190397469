import React from 'react';
import { Layout } from "antd";
import { Grid2, IconButton } from "@mui/material";
import { Icons } from "./SocialMedia";
import UsersSection from "./UsersSection";
import AboutSection from "./AboutSection";

import logo from "assets/images/utriper-black-logo.png"

const { Footer } = Layout;


const FooterComponent = () => {
    return (
        <Footer style={{ textAlign: 'center' }}>
            <Grid2 container>
                <Grid2 size={{xs: 12, sm: 6, md: 3, lg: 3, xl: 3}}>
                    <div style={{ width: "11rem", height: "4rem", marginInline: "auto" }}>
                        <img src={logo} style={{ width: "inherit", height: "inherit" }} />
                    </div>
                </Grid2>
                <Grid2 size={{xs: 12, sm: 6, md: 2, lg: 3, xl: 3}}>
                    <Icons />
                </Grid2>
                <Grid2 size={{xs: 6, sm: 6, md: 3, lg: 2, xl: 3}}>
                    <UsersSection />
                </Grid2>
                <Grid2 size={{xs: 6, sm: 6, md: 4, lg: 4, xl: 3}}>
                    <AboutSection />
                </Grid2>
            </Grid2>
        </Footer>
    );
}

export default FooterComponent;