import React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

import { AppBar as MUIAppBar, Box, Button } from '@mui/material';
import { Menu as MenuIcon, BookmarkBorder } from '@mui/icons-material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoneyDropDown from 'components/MoneyDropDown';
import LanguageDropDown from 'components/LanguageDropDown';

import $ from 'jquery';
// import chroma from 'chroma-js';

import { useAppBar } from './hook';
import { useTranslation } from 'core/contexts/localization';
import { ROUTES } from 'core/routing/routes';
import makeStyles from '@mui/styles/makeStyles';
import palette from 'core/libs/core-ui/theme/colors-palette';

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'flex',
    boxSizing: 'border-box',
    flexShrink: 0,
    flexDirection: 'column',
    backgroundColor: 'transparent',
    zIndex: '100',
    position: 'absolute',
  },
  toolbar: {
    right: '0',
    margin: '1rem',
    height: '2.5rem',
    position: 'absolute',
    zIndex: '100',
    display: 'flex',
  },
  redirectButtons: {
    backgroundColor: palette.lightblue,
    color: 'white',
    margin: '0 0.5rem',
    padding: '0.8rem',
    '&:hover': {
      backgroundColor: palette.lightblue,
    },
  },
  roleText: {},
  highlightRole: {},
  roleSeparator: {},
}));

const AppBar = ({ toggleMenu, open, showUtriperButtons }) => {
  const history = useHistory();
  const location = useLocation();
  const { t: translate } = useTranslation();
  const classes = useStyles();

  const { role, setRole, showBecomeButton, onBecomeClick } = useAppBar();
  return (
    <MUIAppBar
      className={` ${clsx(classes.appBar, open && classes.appBarShift)}`}>
      <Box className={classes.toolbar}>
        {/* <IconButton
          color="primary"
          onClick={toggleMenu}
          edge="start"
          className={classes.menuButton}>
          <MenuIcon />
        </IconButton> */}
        {/* {location.pathname === '/viajero/landing' ? ( */}
        {/* <IconButton
          style={{ color: '#fff', marginRight: 30 }}
          onClick={() => history.push(ROUTES.SAVEDEXPERIENCES)}
          edge="start"
          className={classes.menuButton}>
          <BookmarkBorder />
        </IconButton>
        <IconButton
          style={{ color: '#fff' }}
          onClick={() => history.push(ROUTES.CHECKOUT)}
          edge="start"
          className={classes.menuButton}>
          <ShoppingCartIcon />
        </IconButton> */}
        <LanguageDropDown />
        {showUtriperButtons ? (
          <React.Fragment>
            <Button
              className={classes.redirectButtons}
              onClick={() =>
                window.open('https://app.utriper.com', '_self')
              }>
              {translate('layout.logIn')}
            </Button>
            <Button
              className={classes.redirectButtons}
              onClick={() =>
                window.open('https://app.utriper.com', '_self')
              }>
              {translate('layout.signUp')}
            </Button>
          </React.Fragment>
        ) : null}
        {/* ) : null} */}
        {/* <span className={classes.role}>
          {showBecomeButton ? (
            <Typography
              onClick={onBecomeClick}
              className={clsx(classes.highlightRole, classes.roleText)}>
              {translate('layout.appBar.become')}
            </Typography>
          ) : (
            <>
              {location.pathname === '/home' ? (
                <>
                  <MoneyDropDown />
                  <Button
                    variant={role === 'triper' ? 'contained' : 'outlined'}
                    size={'small'}
                    onClick={() => setRole('triper')}
                    color="secondary"
                    className={clsx(
                      role === 'triper' && classes.highlightRole,
                      classes.roleText
                    )}>
                    Triper
                  </Button>
                  <Divider
                    orientation="vertical"
                    flexItem
                    className={classes.roleSeparator}
                  />
                  <Button
                    size={'small'}
                    variant={role === 'experiencer' ? 'contained' : 'outlined'}
                    color="secondary"
                    onClick={() => setRole('experiencer')}
                    className={clsx(
                      role === 'experiencer' && classes.highlightRole,
                      classes.roleText
                    )}>
                    Experiencer
                  </Button>
                </>
              ) : null}
            </>
          )}
        </span> */}
      </Box>
    </MUIAppBar>
  );
};

AppBar.displayName = 'AppBar';

export default AppBar;
