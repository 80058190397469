import React, { useState, useEffect } from 'react';
import { useTranslation } from 'core/contexts/localization';
import { Grid2, useMediaQuery, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ModusSelect } from 'components/ModusSelect';
import { GooglePlacesAutocomplete } from 'components/CityFilter';
import { RadiusSelect } from 'components/RadiusSelect';
import { useFilters } from 'core/contexts/filter';

const useStyles = makeStyles((theme) => ({
    gridItem: {
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'center',
    },
    autocompleteContainer: {
        width: '90%',
    },
    filtersContainer: {
        width: '95%',
        justifyContent: 'end',
        [theme.breakpoints.down('lg')]: {
            width: '99%',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginRight: 0,
        },
    },
    filterItem: {
        marginRight: '1rem',
        display: 'flex',
        alignItems: 'center',
    },
    filterLabel: {
        marginBottom: 5,
        fontWeight: 'bold',
    },
    modusWrapper: {
        width: '40%',
    },
    filtersWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
        },
    },
}));

export default function Filter(props) {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const [gmapsLoaded, setGmapsLoaded] = useState(false);

    useEffect(() => {
        window.initMap = () => setGmapsLoaded(true);
        const gmapScriptEl = document.createElement(`script`);
        gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places&callback=initMap`;
        document
            .querySelector(`body`)
            .insertAdjacentElement(`beforeend`, gmapScriptEl);
    }, []);

    return (
        <>
            <Grid2 container>
                <Grid2 size={{xs: 12}} className={classes.gridItem}>
                    <Box className={classes.autocompleteContainer}>
                        {gmapsLoaded && <GooglePlacesAutocomplete />}
                    </Box>
                </Grid2>
                <Grid2 size={{xs: 12, sm: 12}}>
                    <Grid2 className={classes.filtersContainer}>
                        <Box>
                            <Box textAlign="center" sx={{mr: 2}}>
                                <p className={classes.filterLabel}>
                                    {translate('filterBy.text')}
                                </p>
                            </Box>
                            <Box className={classes.filtersWrapper}>
                                <Box className={classes.filterItem}>
                                    <RadiusSelect />
                                </Box>

                                <Box className={classes.modusWrapper}>
                                    <ModusSelect />
                                </Box>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
            </Grid2>
        </>
    );
}
