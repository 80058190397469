import Card from './Card';
import Layout from 'components/Layout';
import Button from '@mui/material/Button';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

const SavedExperiences = () => {
  return (
    <>
      <Layout>
        <div className="experiences-grid">
          <div className="grid-300px-auto">
            <div className="mt-10 mb-6">
              <div className="d-flex">
                <p className="experiences-grid__header-title uppercase text-14">
                  Pack and go
                </p>
                <div className="experiences-grid__header-line ml-1" />
                <div className="experiences-grid__header-icon ml-1">
                  <FlightTakeoffIcon />
                </div>
              </div>
              <p className="experiences-grid__header-subtitle mt-2 uppercase bold text-30">
                Saved experiences
              </p>
            </div>
          </div>
          <div className="grid-33-33-33 gap-30 mx-a">
            <Card />
          </div>
          {/* <div className="mt-10 text-center">
            <Button className="mb-10 triper-button bg-blue-300">
              Load more
            </Button>
          </div> */}
        </div>
      </Layout>
    </>
  );
};
export default SavedExperiences;
