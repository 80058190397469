import React, { useState, useEffect } from 'react';
import { SortByPrice } from 'components/Sort/index';
import { useHostel } from 'core/contexts/hostel';
import { useExperiences } from 'core/contexts/experience';
import { useSorts } from 'core/contexts/sort';
import { useTranslation } from 'core/contexts/localization';

import { Grid2, Typography, useMediaQuery } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

import { ModusSelect } from 'components/ModusSelect';
import { TourOperatorSelect } from 'components/TourOperatorSelect';
import { useFilters } from 'core/contexts/filter';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        top: 0,
        marginTop: '10vh',
        marginLeft: '10vw',
        marginRight: '10vw',
    },
    imageWrapper: {
        width: '10rem',
        height: '10rem',
        borderRadius: '50%',
        objectFit: 'cover',
        display: 'flex',
        marginInline: 'auto',
    },
    locationWrapper: {
        textAlign: 'center',
        fontSize: '1rem',
        marginBottom: '1rem',
        paddingInline: '2rem',
    },
    nameWrapper: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginTop: '1rem',
        marginBottom: '0.3rem',
    },
    routeLineWrapper: {
        display: 'flex',
    },
    routeLine: {
        fontSize: '1.4rem',
        marginLeft: '1rem',
        display: 'inline-flex',
    },
    filersContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: '1rem',
        gap: '0.5rem',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '1.2rem',
        },
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            gap: '1.2rem',
        },
    },
}));

export default function SimpleSelect() {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isSm = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    const { code, data } = useHostel();
    // const { getHostelExperiences } = useExperiences();
    // const { priceOrder } = useSorts();
    // const { moduses } = useFilters();

    // useEffect(() => {
    //     if (code != null) {
    //         getHostelExperiences(code, priceOrder, moduses);
    //     }
    // }, [priceOrder, moduses])

    return (
        <>
            <Box className={classes.container}>
                <Grid2 container>
                    <Grid2 size={12}>
                        <Box>
                            <img
                                className={classes.imageWrapper}
                                src={data?.pictureURL}
                            />
                            <Typography
                                variant="h2"
                                className={classes.nameWrapper}>
                                {data?.name}
                            </Typography>
                            <Typography
                                variant="h2"
                                className={classes.locationWrapper}>
                                {data?.location}
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2
                        alignItems={isXs || isSm ? 'flex-start' : 'flex-end'}
                        justifyContent={
                            isXs || isSm ? 'flex-start' : 'flex-end'
                        }
                        size={12}
                        className={classes.filersContainer}
                        spacing={6}>
                        <Box
                            sx={{
                                display: 'flex',
                            }}>
                            <Box
                                sx={{
                                    marginRight: '1rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                }}>
                                {translate('orderBy.text')}
                            </Box>
                            <SortByPrice />
                        </Box>

                        {/* <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}> */}
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                sx={{
                                    marginRight: '1rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                {translate('filterBy.text')}
                            </Box>
                            <Box sx={{ width: '13rem', marginRight: '1rem' }}>
                                <ModusSelect />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '9rem', display: 'grid' }}>
                                <TourOperatorSelect />
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>
        </>
    );
}
