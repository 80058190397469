import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    text: {
        display: 'flex',
        justifyContent: 'center',
    },
    typography: {
        padding: theme.spacing(2),
    },
    helpText: {
        display: 'block',
        padding: theme.spacing(2),
    },
    container: {
        [theme.breakpoints.down('lg')]: {
            width: '85%',
        },
        [theme.breakpoints.up('md')]: {
            width: '55%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '35%',
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '100px',
        marginBottom: '50%',
    },
    authFormDiv: {
        // boxSizing: "border-box",
        // position: "absolute",
        // width: "521px",
        // height: "472px",
        // left: "495px",
        // top: "382px",
        // background: "#F3F3F3",
        // /* Auth Form */
        // position: "absolute",
        // left: "0%",
        // right: "0%",
        // top: "0%",
        // bottom: "0%",
    },
    checkinButton: {
        // position: "absolute",
        width: '100%',
        height: '100px',
        // left: "7.87%",
        // right: "7.68%",
        // top: "66.95%",
        // bottom: "8.69%",

        background: '#FF0000',
    },
    checkinText: {
        // position: absolute;
        // left: 36.47%;
        // right: 35.7%;
        // top: 76.69%;
        // bottom: 18.22%;

        // font-family: 'Inter';
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        // line-height: 24px;

        color: '#FFFFFF',
    },
    checkinFormDiv: {
        marginBottom: '10%',
    },
    checkinConfirmationButton: {
        // position: "absolute",
        // margin: theme.spacing(1),
        width: '250px',
        height: '100px',
        // left: "7.87%",
        // right: "7.68%",
        // top: "66.95%",
        // bottom: "8.69%",

        // background: "#FF0000",
    },
    fieldEmail: {
        marginTop: '1rem',
    },
    fieldPassport: {
        marginTop: '1rem',
    },
    typographyWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
