import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

import { useTranslation } from 'core/contexts/localization';

const TextInput = (props) => {
  const { t: translate } = useTranslation();
  // console.log("TEXT INPUT===", inputProps, "PROPS====", props, "metaaa", touched, props.errorMessage, props.initialError);

  // console.log("props", props)

  const textFieldProps = {
    ...props.input,
    required: props.required,
    className: props.className,
    label: props.label,
    name: props.name,
  };

  const [hasError, setHasError] = useState(props.initialError);
  const [value, setValue] = useState(undefined);

  useEffect(() => setHasError(props.initialError), [props.initialError]);

  return (
    <TextField
      {...textFieldProps}
      onChange={(event) => {
        // console.log("event==", event, "value==", event.target.value)
        setHasError((prevValue) => {
          const retValue = !props.validate(event.target.value);
          props.setFormError(retValue);
          return retValue;
        });
        if (props.onTextFieldChange) props.onTextFieldChange(event);
        setValue(event.target.value);
        props.input.onChange(event);
      }}
      error={hasError || (!value && props.meta.touched)}
      helperText={
        (hasError || (!value && props.meta.touched)) &&
        translate(props.errorMessage(value))
      }
      fullWidth
      variant="outlined"
    />
  );
};

export default TextInput;
