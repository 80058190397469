import React from 'react';

import { Box, Grid2, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

import { useTranslation } from 'core/contexts/localization';

import Filter from '../Filter';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: '0',
        fontSize: '1rem',
    },
    subtitle: {
        margin: '0',
        fontWeight: 'bold',
    },
    lineGreen: {
        borderBottom: '2px solid #1ebb7e', // Estilo de experiences-grid__header-line
        height: '21px',
        width: '50px',
        alignSelf: 'baseline',
    }
}));

const SearchView = () => {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    return (
        <Grid2 container>
            <Grid2 size={12}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{mt: 2}}>
                    <Typography className={classes.title}>
                        {translate('landing.packAndGo')}
                    </Typography>
                    <Box
                        className={classes.lineGreen}
                        sx={{ml: 1, mr: 1}}></Box>
                    <Box>
                        <FlightTakeoffIcon />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" sx={{mt: 1}}>
                    <Typography
                        className={classes.subtitle}
                        variant="h2">
                        {translate('landing.awesomeTours')}
                    </Typography>
                </Box>
            </Grid2>
            <Grid2 size={12}>
                <Filter />
            </Grid2>
        </Grid2>
    );
};

export default SearchView;
