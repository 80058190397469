import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useFilters } from 'core/contexts/filter';
import { useStyles } from './styles';

// 1/5
// MEDELLIN LOCATION DATA
// history.push(
//   '/?lat=6.2476376&lng=-75.56581530000001&location=Medellín%2C Medellin%2C Antioquia%2C Colombia&radius=100'
// );
// setLocationData({
//   name: 'Medellín, Medellin, Antioquia, Colombia',
//       lng: -75.56581530000001,
//       lat: 6.2476376,
// });

// 2/5
// SAN ANDRES LOCATION DATA
// history.push(
//   '/?lat=12.576855&lng=-81.705052&location=San Andrés%2C San Andres and Providencia%2C Colombia&radius=100'
// );
// setLocationData({
// "name": "San Andrés, San Andres and Providencia, Colombia",
// "lng": -81.705052,
// "lat": 12.576855
// });

// 3/5
// SANTA MARTA LOCATION DATA
// history.push(
//   '/?lat=11.2403547&lng=-74.2110227&location=Santa Marta%2C Magdalena%2C Colombia&radius=100'
// );
// setLocationData({
// "name": "Santa Marta, Magdalena, Colombia",
// "lng": -74.2110227,
// "lat": 11.2403547
// });

// 4/5
// TAYRONA LOCATION DATA
// history.push(
//   '/?lat=11.3064409&lng=-74.0657561&location=Tayrona National Park%2C Magdalena%2C Colombia&radius=100'
// );
// setLocationData({
// "name": "Tayrona National Park, Magdalena, Colombia",
// "lng": -74.0657561,
// "lat": 11.3064409
// });

// 5/5
// VILLA DE LEYVA LOCATION DATA
// history.push(
//   '/?lat=5.636499&lng=-73.527058&location=Villa de Leyva%2C Boyaca%2C Colombia&radius=100'
// );
// setLocationData({
// "name": "Villa de Leyva, Boyaca, Colombia",
// "lng": -73.527058,
// "lat": 5.636499
// });

/* DestinationCard receives...
   url, name, lat, lng and with it sets...
   locationData: { name, lat, lng }, radius = 100
   and with that info, when you click on the Card, you:
   1. Change the URL, which will trigger the useEffect of   'Filter by' to autocomplete the name of the city
   2. setLocationData on the Filter context, which will use the URL's 100 km radius to make the call to the API service */
function DestinationCard({ url, name, lat, lng, imageUrl, cardText }) {
  const classes = useStyles();
  const history = useHistory();
  const { setLocationData, setRadius } = useFilters();

  const handleClick = (e) => {
    e.preventDefault();
    // console.log('received CLICK EVENT ON CARD!');
    // console.log('received props...');
    // console.log('url: ', url);
    // console.log('name: ', name);
    // console.log('lat: ', lat);
    // console.log('lng: ', lng);
    // console.log('imageUrl: ', imageUrl);
    // console.log('cardText: ', cardText);
    // Change URL
    history.push(url);
    // Call context setter, which will then trigger API call
    setLocationData({
      name: name,
      lng: lng,
      lat: lat,
    });
    setRadius(100);
  };

  return (
    <Card className={`${classes.custom}`}>
      <Box className={`relative ${classes.custom}`}>
        <CardActionArea
          className={`${classes.cardAction}`}
          onClick={handleClick}>
          <CardContent className={`${classes.cardContent}`}>
            <CardMedia
              className={`relative ${classes.cardMedia}`}
              component="img"
              alt={name}
              image={imageUrl}
              title={name}
            />
            <Typography variant="button" className={`${classes.cardText}`}>
              {cardText}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Box>
    </Card>
  );
}

export default DestinationCard;
